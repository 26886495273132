import AccountBoxIcon from "@mui/icons-material/AccountBox";
import "./UserInfo.css";

const UserInfo = () => {
  return (
    <div className="sideBarUserAccInfo">
      <AccountBoxIcon className="sideBarUserIcon" />
      <div className="sideBarUserAcc">
        <span className="sideBarUserName">D. Hettiarachchi</span>
        <span className="sideBarLastLogin">
          Last Login : Wed, 28 Feb 2024 10:12:15
        </span>
        <span className="sideBarUpdateProfile">Update Profile</span>
      </div>
    </div>
  );
};

export default UserInfo;
