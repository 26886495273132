import React from "react";
import "./DropDown.css";

interface DropdownProps {
  selectedOption: string;
  setSelectedOption: (option: string) => void;
  options: string[];
  width: string;
}

function Dropdown({
  selectedOption,
  setSelectedOption,
  options,
  width = "8rem",
}: DropdownProps) {
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="dropdown">
      <select
        value={selectedOption}
        onChange={handleOptionChange}
        className="dropdown-select"
        style={{ width: width }}
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Dropdown;
