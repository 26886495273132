import React, { useState } from "react";
import logo from "../assets/StockWatchLogo.png";
import video from "../assets/candle-stick-bg-video.mp4";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PersonIcon from '@mui/icons-material/Person';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import "./Login.css";
import { Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function Login () {
  const [passwordVisibililty,setPasswordVisibililty] = useState("password")
  const navigate = useNavigate();
  const handleForgotPassword = () => {
    console.log("Forgot password clicked!");
  };
  const handleCreate = () => {
    console.log("Create clicked!");
  };
  
  const handleLogin = () => {
    console.log("Arrow Icon clicked!");
      navigate("/")
  };

  const changeVisibility=()=>{
    if (passwordVisibililty !== "password"){
        setPasswordVisibililty("password")
    } else {
        setPasswordVisibililty("text")
    }
  }
  
    return (
        <div className="main">
            
        <video src={video} autoPlay loop muted/>
        <div className="sub-main">
            <div>
            <div className="imgs">
                <div className="container-imgs">
                <img src={logo} alt="profile" className="logo" />
                </div>
            </div>
            <div className="sign-in">
                <h4>Sign in to your account</h4>
                <div className="name-div common" >
                    <PersonIcon/>
                <input type="text" placeholder=" user name" className="name" />
                
                </div>
                <div className="password common">
                    <LockOpenIcon/>
                <input type={passwordVisibililty} placeholder=" password " className="name" />
                {passwordVisibililty === "password" ?
                    <VisibilityOffIcon onClick={changeVisibility}/>
                    :<Visibility onClick={changeVisibility}/>}

        
                </div>
                <div className="RememberMe-ForgotPassword">
                <label className="remember-me">
                    <input type="checkbox" />
                    Remember me
                </label>
                <div className="forgot-password" onClick={handleForgotPassword}>Forgot Password?</div>
                </div>
                <div className="submit-container">
                <div className="submit">Sign in</div>
                    <ArrowForwardIcon className="login-arrow-icon button" onClick={() => handleLogin()}/>
                </div>
                <div className="create-account" onClick={handleCreate}>Don't have an account?<span className="create underline">Create</span></div>
            </div>
            </div>
        </div>
    </div>
  );
}


        
        