// import React,{useEffect,useRef} from "react";
import Logo from "./Logo/Logo";
import StockSelector from "./StockSelector/StockSelector";
import UserInfo from "./UserInfo/UserInfo";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// import "./SideBar.css";

// // const SideBar = () => {
//   interface SidebarProps {
//     sidebarToggled: boolean;
//     setSidebarToggled: React.Dispatch<React.SetStateAction<boolean>>;
//   }
// function Sidebar({sidebarToggled,setSidebarToggled}: SidebarProps){
//   const sideBarRef = useRef(null);
//   console.log(sideBarRef.current)
//   function handleOutsideClick(event: MouseEvent){
//     if (sideBarRef.current !== null && sideBarRef.current(event.target)){
//       setSidebarToggled(false);
//     }
//   }
//   useEffect(() =>{
//     if(sidebarToggled){
//       document.addEventListener('click',handleOutsideClick);
//     }

//     return() => {
//       document.removeEventListener('click',handleOutsideClick);
//     };

//   },[sidebarToggled]);

//   return (
//     // <div>
//     //   <Logo />
//     //   <UserInfo />
//     //   <StockSelector />
//     //   <div className="stock-sltr-submit">
//     //     <ArrowForwardIcon className="stock-sltr-arrow-btn" />
//     //   </div>
//     // </div>
//     <div>
//       <div
//          ref={sideBarRef}
//          className={`sideBarRootContainer ${sidebarToggled ? "open" : ""}`}></div>
//       <div className={`blackbackground ${sidebarToggled ? "open" : ""}`}></div>
//     </div>
//   );
// };

// export default Sidebar;

import './SideBar.css'

interface SidebarProps {
      sidebarToggled: boolean;
      setSidebarToggled: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function({sidebarToggled,setSidebarToggled}:SidebarProps){
  return (
    <div className={sidebarToggled? "sidebar sidebaropen":"sidebar"}>

    
      
      {/* <button onClick={()=>setSidebarToggled((prev)=>!prev)}>
        mongal sachini
      </button> */}

      <div>
          <Logo />
          <UserInfo />
          <StockSelector />
          <div className="stock-sltr-submit">
            <ArrowForwardIcon className="stock-sltr-arrow-btn" onClick={()=>setSidebarToggled((prev)=>!prev)}/>
          </div>
      </div>
    </div>
  )
}