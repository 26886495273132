import { useState } from "react";
import Dropdown from "../../../../SideBar/StockSelector/Dropdowns/DropDown";
import "./DesignPortfolio.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from '@mui/icons-material/Close';

const DesignPortfolio = () => {
  const [selectedinvestmentamountOption, setSelectedinvestmentamountOption] =
    useState("");
  const [investmentamountOptions, setinvestmentamountOptions] = useState([
    "LKR", "USD"
  ]);

  const [
    selectedHoldingPeriodReturnOption,
    setSelectedHoldingPeriodReturnOption,
  ] = useState("");
  const [HoldingPeriodReturnOptions, setHoldingPeriodReturnOptions] = useState([
    "Days",
    "Weeks",
    "Months",
    "Years",
  ]);
  return (
    <div>
      <h1>Design Portfolio</h1>
      <div className="Design-Portfolio">
      <div className="investment-amount">
        <span>Investment Amount</span>
        <input type="text" className="data-period-sidebar-number"/>
        <Dropdown
          options={investmentamountOptions}
          selectedOption={selectedinvestmentamountOption}
          setSelectedOption={setSelectedinvestmentamountOption}
          width="8rem"
        />
        
      </div>

      <div className="Risk-Beta">
        <span>Risk(Volatility) Beta</span>
        <input type="text" className="data-period-sidebar-number"/>
      </div>

      <div className="Holding-period">
        <span>Holding Period</span>
        <input type="text" className="data-period-sidebar-number"/>
      </div>

      <div className="Holding-period-Returns">
        <span>Holding period Returns</span>
        <input type="text" className="data-period-sidebar-number"/>
        <Dropdown
          options={HoldingPeriodReturnOptions}
          setSelectedOption={setSelectedHoldingPeriodReturnOption}
          selectedOption={selectedHoldingPeriodReturnOption}
          width="8rem"
        />
        
      </div>
    </div>
    <div className="btn-design-port">
      <CloseIcon className="close-btn"/>
      <ArrowForwardIcon className="arrow-btn"/>
    </div>
    </div>
  );
};

export default DesignPortfolio;
