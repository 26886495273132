import TabsList from "./Tabs/TabsList";
import "./RightMainLayout.css";
import Header from "./Header/Header";
import { Container } from "@mui/material";
import { useState } from "react";
import TechnicalCharts from "./Tabs/SelectionTabs/TechnicalCharts/TechnicalCharts";
import Recommendations from "./Tabs/SelectionTabs/Recommendations/Recommendations";
import Watchlist from "./Tabs/SelectionTabs/WatchList/WatchList";
import Alerts from "./Tabs/SelectionTabs/Alerts/Alerts";
import DesignPortfolio from "./Tabs/SelectionTabs/DesignPortfolio/DesignPortfolio";


interface setSidebarToggledProp{
    setSidebarToggled: React.Dispatch<React.SetStateAction<boolean>>;
}
const RightMainLayout = ({setSidebarToggled}:setSidebarToggledProp) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const tablist = [
    { title: "Technical Charts", screenKey: 1, screen: <TechnicalCharts /> },
    { title: "Recommendations", screenKey: 2, screen: <Recommendations /> },
    { title: "Watchlist", screenKey: 3, screen: <Watchlist /> },
    { title: "Alerts", screenKey: 4, screen: <Alerts /> },
    { title: "Design Portfolio", screenKey: 5, screen: <DesignPortfolio /> },
  ];
  const renderSelectedTab = () => {
    const selectedTabScreen = tablist.find(
      (tab) => tab.screenKey === selectedTab 
    );
    return (
      selectedTabScreen?.screen || <div>We're Working On Something Great!</div>
    );
  };
  return (
    <Container>
      
      <div className="RightMainLayout">
      <Header setSidebarToggled={setSidebarToggled} />
        <TabsList
          tablist={tablist}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <div className="selectedTab">{renderSelectedTab()}</div>
      </div>
    </Container>
  );
};

export default RightMainLayout;
