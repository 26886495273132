import SelectorIcon from "./SelectorIcon";
import "./ChartTypes.css";

interface ChartType {
  title: string;
  screenKey: number;
}
interface ChartTypesProps {
  chartTypes: ChartType[];
  selectedChartType: number;
  setSelectedChartType: (screenKey: number) => void;
}

const ChartTypes: React.FC<ChartTypesProps> = ({
  chartTypes,
  selectedChartType,
  setSelectedChartType,
}) => {
  return (
      <div className="outer-charttype">
    <div className="charttype-wrapper">

      {chartTypes.map((item, index) => (
        <SelectorIcon
          chartNumber={index + 1}
          selectedChartType={selectedChartType}
          setSelectedChartType={setSelectedChartType}
          title={item.title}
        />
      ))}
      </div>
    </div>
  );
};

export default ChartTypes;
