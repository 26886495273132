import React from "react";
import Tab from "./Tab";
import "./TabsList.css";

interface TabsListProps {
  setSelectedTab: (screenKey: number) => void;
  selectedTab: number;
  tablist: { title: string; screenKey: number }[];
}

const TabsList: React.FC<TabsListProps> = ({
  tablist,
  selectedTab,
  setSelectedTab,
}) => {
  return (
    <div className="tabWrapper">
      {tablist.map((tab, index) => (
        <Tab
          key={index}
          title={tab.title}
          screenKey={tab.screenKey}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab === tab.screenKey ? true : false}
        />
      ))}
    </div>
  );
};

export default TabsList;
