var system_logo = require("../../../assets/StockWatchLogo.png");

const Logo = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
      }}
    >
      <img
        src={system_logo}
        alt="Stock Watch"
        style={{
          width: "100%",
        }}
      />
    </div>
  );
};

export default Logo;
