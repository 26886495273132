import React from "react";
import "./SelectorIcon.css";

interface SelectorIconProps {
  title: string;
  selectedChartType: number;
  setSelectedChartType: (screenKey: number) => void;
  chartNumber: number;
}

const SelectorIcon: React.FC<SelectorIconProps> = ({
  title,
  selectedChartType,
  setSelectedChartType,
  chartNumber,
}) => {
  return (
    <div
      className="selector-icon"
      onClick={() => {
        setSelectedChartType(chartNumber);
      }}
      style={{
        backgroundColor:
          selectedChartType === chartNumber
            ? "rgb(0, 0, 0)"
            : "rgb(255, 255, 255)",
        color:
          selectedChartType === chartNumber
            ? "rgb(255, 255, 255)"
            : "rgb(0, 0, 0)",
      }}
    >
      {/* <LeaderboardIcon /> */}
      {title}
    </div>
  );
};

export default SelectorIcon;
