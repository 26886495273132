import { Routes, Route } from "react-router-dom";
import MainStructure from "./Home/MainStructure/MainStructure";
import Login from "./Login/Login";

export default function App() {
  return (
    <Routes>
      <Route path="/Login" element={<Login />}></Route>
      <Route path="/" element={<MainStructure />}></Route>
    </Routes>
  );
}

