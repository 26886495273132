import React, { useEffect, useState } from "react";
import "./Tab.css";

interface TabProps {
  title: string;
  screenKey: number;
  selectedTab?: boolean;
  setSelectedTab: (screenKey: number) => void;
}

const Tab: React.FC<TabProps> = ({
  title,
  screenKey,
  selectedTab = false,
  setSelectedTab,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  if (!(title.indexOf(" ") > 0) && title.length > 9 && windowWidth < 768) {
    let firstPart = title.slice(0, 9);
    let secondPart = title.slice(9);
    title = firstPart + "\n-" + secondPart;
  }
  return (
    <div
      onClick={() => {
        setSelectedTab(screenKey);
      }}
      className="right-view-tab"
      style={{
        color: selectedTab ? "white" : "black",
        background: selectedTab
          ? "linear-gradient(to left, rgb(170, 168, 168), rgb(0, 0, 0))"
          : "white",
        border: selectedTab ? "none" : "1px solid #EBEBE4",
      }}
    >
      {title}
    </div>
  );
};

export default Tab;
