import React,{useState} from "react";
import "./MainStructure.css";
import SideBar from "../SideBar/SideBar";
import RightMainLayout from "../RightMainLayout/RightMainLayout";



const MainStructure = () => {
//   // const handlesidebar = () => {
//   //   console.log("threee-lines-icon clicked!");
// const[sidebarToggled,setSidebarToggled] = useState(false)
// const sidebarRef = useRef(null)
// useEffect(() => {
//   function handler(e){
//     if (sidebarRef.current){
//       if (e.target.classList.contains('sidebar'))
//         setSidebarToggled(false)
//     }
//     document.addEventListener("click",handler)
//     return() => {
//       document.removeEventListener("click",handler)
//     }
//   }
// })
    

      
    
  
//   return (
//     <div className="MainStructureWrapper">
      
//       <DehazeIcon className="sidebar-toggle" onClick={() => setSidebarToggled(true)} />
//         {/* openNav() */}
        
//         <aside ref={sidebarRef} className= {'${ sidebarToggled? "visible':""}}>
//       {/* </div> */}
//       <div className="MainView">
//         <RightMainLayout />
//       </div>
//     </div>
//   );

const[sidebarToggled,setSidebarToggled] = useState(false);

  return(
    <div className="MainStructureWrapper">
      <SideBar sidebarToggled={sidebarToggled} setSidebarToggled={setSidebarToggled}/>


      <div className="MainView">
        <RightMainLayout setSidebarToggled={setSidebarToggled}/>
      </div>
    </div>
  );
  };
export default MainStructure;
