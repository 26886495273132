 import { useState } from "react";
import Dropdown from "./Dropdowns/DropDown";
import "./StockSelector.css";

const StockSelector = () => {
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [countryOptions, setCountryOptions] = useState([
    "Sri Lanka -  CSE",
    "USA - NASDAQ",
    "USA - NYSE",
  ]);

  const [selectedStockOption, setSelectedStockOption] = useState(null);
  const [stockOptions, setStockOptions] = useState([
    "HNB.N0000",
    "COMB.N1200",
    "SMPTH.N1200",
  ]);

  const [selectedDataPeriodOption, setSelectedDataPeriodOption] =
    useState(null);
  const [dataPeriodOptions, setDataPeriodOptions] = useState([
    "Weeks",
    "Months",
    "Years",
  ]);

  return (
    <div className="stock-selector-main">
      <div className="stock-sltr-country-main">
        <span>Country</span>
        <Dropdown
          options={countryOptions}
          selectedOption={selectedCountryOption}
          setSelectedOption={setSelectedCountryOption}
        />
      </div>
      <div className="stock-sltr-stock-main">
        <span>Stock Name</span>
        <Dropdown
          options={stockOptions}
          selectedOption={selectedStockOption}
          setSelectedOption={setSelectedStockOption}
        />
      </div>
      <div className="stock-sltr-data-period-main">
        <span>Period</span>
        <div className="data-period-sidebar">
          <input type="text" className="data-period-sidebar-number" />
          <Dropdown
            options={dataPeriodOptions}
            selectedOption={selectedDataPeriodOption}
            setSelectedOption={setSelectedDataPeriodOption}
            width="5rem"
          />
        </div>
      </div>
      <div className="stock-sltr-date-picker">
        <div className="stock-sltr-from">
          <span>From</span> &nbsp;
          <input type="date" />
        </div>
        <div className="stock-sltr-to">
          <span>To</span> &nbsp;
          <input type="date" />
        </div>
      </div>
    </div>
  );
};

export default StockSelector;
