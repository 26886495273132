import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import DehazeIcon from '@mui/icons-material/Dehaze';

import "./Header.css";
interface SetSidebarToggledProp {
  setSidebarToggled: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<SetSidebarToggledProp> = ({ setSidebarToggled }) => {
  const navigate = useNavigate();
  function handleLogout(){
    navigate("/login")
  }
  return (
    <div className="header">
      <div className="barsButton" onClick={()=>setSidebarToggled((prev)=> !prev)}>
         <DehazeIcon />
      </div >
      <div className="right">
      <NotificationsIcon className="notification-icon" />
      <LogoutIcon className="logout-icon" onClick={() => handleLogout()}/>
      </div>
    </div>
  );
};
export default Header;
// function onClick() {
//   const navigate = useNavigate();
//   const navigateToPage = ()=>{
//   navigate("/logout-icon");
// }

// return (
//   <button onClick={() => Navigate('/logout-icon')}>LogoutIcon</button>
// );
// }