import { useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import "./WatchList.css";
import Dropdown from "../../../../SideBar/StockSelector/Dropdowns/DropDown";
import SearchIcon from "@mui/icons-material/Search";

const columns: GridColDef<(typeof rows)[number]>[] = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "stockname",
    headerName: "Stock Name",
    width: 150,
    editable: true,
  },
  {
    field: "sector",
    headerName: "Sector",
    width: 80,
    editable: true,
  },
  {
    field: "closingprice",
    headerName: "Closing Price",
    type: "number",
    width: 100,
    editable: true,
  },
  {
    field: "return",
    headerName: "Return",
    renderHeader: (params) => (
      <div
        style={{
          width: "120px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <span>Return</span>

        <div
          style={{
            width: "120px",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <div>3</div>
          <div>6</div>
          <div>12</div>
        </div>
      </div>
    ),
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    // valueGetter: (value, row) => `${row.firstName || ""} ${row.lastName || ""}`,
    renderCell: (params) => {
      if (params.row) {
        console.log(params);
        return (
          <div>
            <span>{params.row.return.three}        </span>
            <span>{params.row.return.six}   </span>
            <span>{params.row.return.twelve}  </span>
          </div>
        );
      }
      return <div>N/A</div>;
    },
  },
  {
    field: "volatility",
    headerName: "Volatility",
    renderHeader: (params) => (
      <div
        style={{
          width: "120px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <span>Volatility</span>

        <div
          style={{
            width: "120px",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <div>3</div>
          <div>6</div>
          <div>12</div>
        </div>
      </div>
    ),
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    // valueGetter: (value, row) => `${row.firstName || ""} ${row.lastName || ""}`,
    renderCell: (params) => {
      if (params.row) {
        console.log(params);
        return (
          <div>
            <span>{params.row.volatility.three}|</span>
            <span>{params.row.volatility.six}|</span>
            <span>{params.row.volatility.twelve}</span>
          </div>
        );
      }
      return <div>N/A</div>;
    },
  },
  {
    field: "liquidity",
    headerName: "Liquidity",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "mktcap",
    headerName: "Mkt.cap",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "dividend",
    headerName: "Dividend",
    type: "number",
    width: 110,
    editable: true,
  },
];

const rows = [
  {
    id: 1,
    stockname: "EXPO.N0000(Expolan..)",
    sector: "Logistics",
    closingprice: 1000,
    return: {
      three: "70%",
      six: "72%",
      twelve: "72%",
    },
    volatility: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    liquidity: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    mktcap: 14,
    dividend: 14,
  },
  {
    id: 2,
    stockname: "JKH.N0000(John Keells..)",
    sector: "BFI",
    closingprice: 1200,
    return: {
      three: "25%",
      six: "15%",
      twelve: "12%",
    },
    volatility: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    liquidity: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    mktcap: 14,
    dividend: 14,
  },
  {
    id: 3,
    stockname: "CTC.N0000(Ceylon To..)",
    sector: "Tobacco",
    closingprice: 800,
    return: {
      three: "25%",
      six: "10%",
      twelve: "18%",
    },
    volatility: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    liquidity: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    mktcap: 14,
    dividend: 14,
  },
  {
    id: 4,
    stockname: "AFSL.N0000(Abans..)",
    sector: "BFI",
    closingprice: 600,
    return: {
      three: "23%",
      six: "15%",
      twelve: "10%",
    },
    volatility: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    liquidity: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    mktcap: 14,
    dividend: 14,
  },
  {
    id: 5,
    stockname: "KFP.N0000(Keells..)",
    sector: "Retail",
    closingprice: 450,
    return: {
      three: "21%",
      six: "10%",
      twelve: "13%",
    },
    volatility: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    liquidity: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    mktcap: 14,
    dividend: 14,
  },
  {
    id: 6,
    stockname: "LFIN.N0000(LB Fin..)",
    sector: "BFI",
    closingprice: 570,
    return: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    volatility: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    liquidity: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    mktcap: 14,
    dividend: 14,
  },
  {
    id: 7,
    stockname: "ODEL.N0000(Odel..)",
    sector: "Retail",
    closingprice: 300,
    return: {
      three: "13%",
      six: "14%",
      twelve: "16%",
    },
    volatility: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    liquidity: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    mktcap: 14,
    dividend: 14,
  },
  {
    id: 8,
    stockname: "HNB.N0000(Hatton N..)",
    sector: "BFI",
    closingprice: 197,
    return: {
      three: "11%",
      six: "20%",
      twelve: "18%",
    },
    volatility: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    liquidity: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    mktcap: 14,
    dividend: 14,
  },
  {
    id: 9,
    stockname: "CARG.N0000(Cardills..)",
    sector: "Retail",
    closingprice: 276,
    return: {
      three: "10%",
      six: "10%",
      twelve: "12%",
    },
    volatility: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    liquidity: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    mktcap: 14,
    dividend: 14,
  },
  {
    id: 10,
    stockname: "TILE.N0000(Lanka Til..)",
    sector: "Capital goods",
    closingprice: 443,
    return: {
      three: "10%",
      six: "15%",
      twelve: "14%",
    },
    volatility: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    liquidity: {
      three: "18%",
      six: "17%",
      twelve: "16%",
    },
    mktcap: 14,
    dividend: 14,
  },
];

export default function WatchList() {
  const [selectedEntriesPage, setSelectedEntriesPage] = useState("");
  const [EntriesPage, setEntries] = useState(["10", "25", "50", "100"]);

  return (
    <div>
      <div className="watchlist-listnames">
        <span>
          List of companies listed on the Colombo Stock Exchange-Sri Lanka
        </span>

        <div className="entries-per-page">
          <Dropdown
            options={EntriesPage}
            setSelectedOption={setSelectedEntriesPage}
            selectedOption={selectedEntriesPage}
            width="8rem"
          />
          <div className="entries-search">
            <SearchIcon />
            <input type="text" className="entries-values" />
          </div>
        </div>
      </div>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </div>
  );
}
