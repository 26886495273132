import React, { useState } from "react";
import "./Alerts.css";
import FavoriteIcon from "@mui/icons-material/Favorite";

const Alerts = () => {
  const [selection, setSelection] = useState("");
  const [alerts, setAlerts] = useState([
    {
      alertHead: "Alert Sub Head 1",
      alertSubHead: "Alert Body Text 1......",
      alertBody: "..........................",
    },
    {
      alertHead: "Alert Sub Head 2",
      alertSubHead: "Alert Body Text 2......",
      alertBody: "..........................",
    },
    {
      alertHead: "Alert Sub Head 3",
      alertSubHead: "Alert Body Text 2......",
      alertBody: "..........................",
    },
  ]);

  const handleSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelection(event.target.value);
  };

  const handleWatchListAlerts = () => {
    console.log("Watch List Alerts clicked!");
  };

  return (
    <div>
      <div className="radio-btn-line">
        <div className="Radio-group" onChange={handleSelectionChange}>
          <div>
            <input
              className="radio-selections-alerts-group"
              type="radio"
              value="All"
              name="selection"
            />{" "}
            All
            <input
              className="radio-selections-alerts-group"
              type="radio"
              value="Charts Only"
              name="selection"
            />{" "}
            Charts Only
            <input
              className="radio-selections-alerts-group"
              type="radio"
              value="Stock Alerts"
              name="selection"
            />
            Stock Alerts
          </div>

          <div className="alerts-watchlist-favicon">
            <div className="watch-list-alerts" onClick={handleWatchListAlerts}>
              WatchList Alerts
            </div>
            <FavoriteIcon className="favorite-icon" />
          </div>
        </div>
      </div>
      {alerts.map((alert) => (
        <div className="Alerts-box">
          <div className="alert-sub-box"></div>
          <div className="alert-content">
            <div className="alert-head">{alert.alertHead}</div>
            <div className="alert-subHead">{alert.alertSubHead}</div>
            <div className="alert-body">{alert.alertBody}</div>
          </div>
          <div className="alerts-timestamp">
            <span>Today</span>
            <span>Just Now</span>
          </div>
        </div>
      ))}
      <div></div>
    </div>
  );
};

export default Alerts;
