import { useState } from "react";
import ChartTypes from "./ChartTypes/ChartTypes";
import OpenHightLowClose from "./Charts/OpenHighLowClose";

const TechnicalCharts = () => {
  const [selectedChartType, setSelectedChartType] = useState(1);
  const chartTypes = [
    {
      title: "Open High Low Close",
      screenKey: 1,
    },
    {
      title: "Closing Stock Price",
      screenKey: 2,
    },
    {
      title: "ASPI",
      screenKey: 3,
    },
    {
      title: "Last Traded Volume",
      screenKey: 4,
    },
    {
      title: "Historical Prices Volume",
      screenKey: 5,
    },
    {
      title: "CSP SR",
      screenKey: 6,
    },
    {
      title: "ASPI ASPIR",
      screenKey: 7,
    },
    {
      title: "SR ASPIR",
      screenKey: 8,
    },
    {
      title: "SRASPI Scatter Regression",
      screenKey: 9,
    },
    {
      title: "RSI",
      screenKey: 10,
    },
    {
      title: "Bollinger Bands",
      screenKey: 11,
    },
    {
      title: "Bollinger Squeeze",
      screenKey: 12,
    },
    {
      title: "Money Flow Index",
      screenKey: 13,
    },
    {
      title: "SMA",
      screenKey: 14,
    },
  ];
  return (
    <div>
      <ChartTypes
        chartTypes={chartTypes}
        selectedChartType={selectedChartType}
        setSelectedChartType={setSelectedChartType}
      />
      <div className="actualChart">
        <OpenHightLowClose />
      </div>
    </div>
  );
};

export default TechnicalCharts;
